<template>
  <div class="grey-bg" v-show="template_status">
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="address.length > 10 ? '没有更多了': ''"
        :immediate-check="false"
        @load="onLoad"
    >
      <!-- 收货地址列表 -->
      <div class="address">
        <!--地址列表开始-->
        <div class="address-item" v-for="(item,index) in address" :key="index" @click.stop="choiceAddress(item)">
          <div class="consignee-info">
            <div class="consignee-name">{{ item.contact_name }}</div>
            <div class="consignee-tell">{{ item.contact_phone }}</div>
          </div>
          <div class="consignee-address">
            {{ item.province }}{{ item.city }}{{ item.area }} {{ item.address }}
          </div>
          <div class="consignee-state space-between">
            <div class="vertical-center default-change-box" @click.stop="defaultChange(item)">
              <img class="state-img" v-if="item.is_default" src="~assets/images/user/overlay-redio2.png"/>
              <img class="state-img" v-else src="~assets/images/user/overlay-redio1.png"/>
              <span class="state-text">默认</span>
            </div>

            <div class="consignee-edit-con">
              <div class="consignee-edit vertical-center" @click.stop="editFun(item.id)" >
                <img class="state-img" src="~assets/images/user/edit-icon.png"/>
                <div class="state-text">编辑</div>
              </div>
              <div class="consignee-del vertical-center" @click.stop="delFun(item.id)">
                <img class="state-img" src="~assets/images/user/del-icon.png"/>
                <div class="state-text">删除</div>
              </div>
            </div>
          </div>
        </div>

        <!--地址列表结束-->
      </div>
    </van-list>

    <no-data v-if="address.length == 0"></no-data>

    <!--添加新地址按钮开始-->
    <div class="bot-height safety-height"></div>
    <div class="address-btn-box safety-height">
      <div class="address-btn" @click="goRreate">添加新地址</div>
    </div>
    <!--添加新地址按钮结束-->
  </div>
</template>

<script>
import NoData from "@/components/NoData";
export default {
  name: "address-index",
  data() {
    return {
      template_status: false,
      radio: [],
      address: [],
      full: 1,
      pageSize: 10,
      full_status: true,  //判断分页状态
      loading_status: false,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.$route.meta.backUrl = '/user';
    if(this.$route.query.f){
      localStorage.setItem('address_f',this.$route.query.f)
      this.$route.meta.backUrl = '/order/confirm?f=' + this.$route.query.f + '&type=' + this.$route.query.type
    }
    if(localStorage.getItem('address_f')){
      this.$route.meta.backUrl = '/order/confirm?f=' +localStorage.getItem('address_f') + '&type=' + this.$route.query.type
    }

    // 获取列表
    this.getAddress()
  },
  mounted() {
    this.$nextTick(()=>{
      this.$util.domMinHeight()
    })
  },
  methods: {
    //获取列表数据
    getAddress(){
      let url = this.$api.Address + '?page=' + this.full + '&pageSize=' + this.pageSize;
      this.full++;
      this.finished = true;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.template_status = true;
          this.loading = false;
          if(  this.full < res.data.data.last_page + 2){
            this.finished = false;
            this.address = this.address.concat(res.data.data.data)
          }else {
            this.finished = true;
          }
        }
      })
    },
    // 设置默认地址
    defaultChange(item){
      let url = this.$api.Address + '/' + item.id;
      let data = {
        is_default: !item.is_default
      }
      this.$http.put(url,data,true).then( res => {
        if(res.data.success){
          this.resetFun();
          this.getAddress()
        }
      })
    },
    // 编辑地址
    editFun(id){
      this.$router.push({path: `/user/address/edit/${id}`,query:{f:this.$route.query.f}})
    },
    // 删除
    delFun(id){
      let _this = this;
      this.$dialog.confirm({
        message: '确定要删除该地址吗？',
        confirmButtonColor: '#ff6b29'
      }).then(() => {
        _this.delData(id)
      })
    },
    delData(id){
      let _this = this;
      let url = this.$api.Address + '/' + id
      this.$http.del(url,true).then( res => {
        if(res.data.success){
          _this.$toast(res.data.message);
          _this.resetFun();
          _this.getAddress();
        }else {
          _this.$toast(res.data.message);
        }
      })
    },
    // 恢复默认数据
    resetFun(){
      this.address = [];
      this.full = 1;
      this.full_status = true;
      this.loading_status = false
      this.loading= false
      this.finished= false
    },
    // 添加地址
    goRreate(){
      this.$router.push({path: '/user/address/create',query:{f:this.$route.query.f}})
    },
    //  底部加载
    onLoad(){
      this.getAddress();
    },
    // 选择地址
    choiceAddress(item){
      if(this.$route.query.type == 'flea'){
        this.$router.replace({
          path: '/order/confirm',
          query: {
            f: this.$route.query.f,
            type:this.$route.query.type,
            address_id:item.id
          }
        })
      }else{
      if(localStorage.getItem('address_f')){
        let data = this.$handle.decrypt(localStorage.getItem('address_f'));
        data.address_id = item.id;
        localStorage.removeItem('address_f')
        this.$router.replace({
          path: '/order/confirm',
          query: {
            f: this.$handle.encryption(data),
            type:this.$route.query.type
          }
        })
      }
      }
     
    }
  },
  components: {
    NoData
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/address/index.scss";
</style>